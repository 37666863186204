/* @import url("https://rsms.me/inter/inter.css");

:root {
    --color-gray: #737888;
    --color-lighter-gray: #e3e5ed;
    --color-light-gray: #f7f7fa;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
    html {
        font-family: "Inter var", sans-serif;
    }
}

body {
    margin: 0;
}

h1 {
    margin-bottom: 1rem;
} */
/* 
p {
    color: var(--color-gray);
}

hr {
    height: 1px;
    width: 100%;
    background-color: var(--color-light-gray);
    border: 0;
    margin: 2rem 0;
}

.container {
    max-width: 40rem;
    padding: 10vw 2rem 0;
    margin: 0 auto;
    height: 100vh;
}

.form {
    display: grid;
    grid-gap: 1rem;
}

.field {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-lighter-gray);
    padding: 0.5rem;
    border-radius: 0.25rem;
}

.field__label {
    color: var(--color-gray);
    font-size: 0.6rem;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
}

.field__input {
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    font-weight: bold;
    font-size: 1rem;
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
}

.field:focus-within {
    border-color: #000000;
}

.fields {
    display: grid;
    grid-gap: 1rem;
}

.fields--2 {
    grid-template-columns: 1fr 1fr;
}

.fields--3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.button {
    background-color: #000;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    display: block;
    color: #fff;
    width: 100%;
    padding: 1rem;
    border-radius: 0.25rem;
    border: 0;
    cursor: pointer;
    outline: 0;
}

.button:focus-visible {
    background-color: #333;
} */

.adress_wrapper {
    width: 900px;
    border: 1px solid black;
    margin: 40px auto;
    padding: 40px;
    background-color: gainsboro;
    /* display: flex;
    justify-content: space-between; */
}

.add-detail {
    /* border: 1px solid black; */
    padding: 15px;
    box-sizing: border-box;
   
}
.addres {
    border: 1px solid black;
    border-radius: 5px;
    padding: 15px;
    background-color: #f3f3f6;
}
.add-name-wrapper {
    display: flex;
    align-items: center;
    margin: 3px;
    /* justify-content: center; */
}
.add-name-wrapper h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
}
.add-name-wrapper span {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 600;
    color: gray;
    text-transform: capitalize;
}