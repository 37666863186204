/* * {
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
} */

/* body {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.tags_inp_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.1px solid rgba(128, 128, 128, 0.221);
}

.tags-input-container {
    /* border: 2px solid black; */
    /* padding: 20px; */
    /* border-radius: 3px; */
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: .5em;
}

.tag-item {
    background-color: #177bbb;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 4px;
    color: #fff;
    font-size: 13px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: start;

}

.tag-item .close {
    height: 15px;
    width: 15px;
    background-color: gainsboro;
    display: inline-block;
    color: black;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    font-size: 16px;
    cursor: pointer;

}

.tags-input {
    border: none;
    flex-grow: 1;
    outline: none;
    padding: 5px;

}

/* //size --/ */
.size_tags_inp_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.1px solid rgba(128, 128, 128, 0.221);
}

.size-tags-input-container {
    width: 100%;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: .5em;
}

.size-tag-item {
    background-color: #177bbb;
    display: inline-block;
    padding: 8px;
    border-radius: 10px;
    color: #fff;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: bold;
    letter-spacing: 1px;
}

.size-tag-item .size-close {
    height: 20px;
    width: 20px;
    background-color: #79abcb;
    display: inline-block;
    color: black;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    font-size: 18px;
    cursor: pointer;
}

.size-tags-input {
    border: none;
    flex-grow: 1;
    outline: none;
    padding: 5px;
}